/**
 * @license
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { __assign } from "tslib";
import { fromRef } from '../fromRef';
import { map } from 'rxjs/operators';
export function doc(ref) {
    return fromRef(ref, { includeMetadataChanges: true });
}
/**
 * Returns a stream of a document, mapped to its data payload and optionally the document ID
 * @param query
 * @param options
 */
export function docData(ref, options) {
    if (options === void 0) { options = {}; }
    return doc(ref).pipe(map(function (snap) { return snapToData(snap, options); }));
}
export function snapToData(snapshot, options) {
    var _a;
    if (options === void 0) { options = {}; }
    var data = snapshot.data(options);
    // match the behavior of the JS SDK when the snapshot doesn't exist
    // it's possible with data converters too that the user didn't return an object
    if (!snapshot.exists() || typeof data !== 'object' || data === null || !options.idField) {
        return data;
    }
    return __assign(__assign({}, data), (_a = {}, _a[options.idField] = snapshot.id, _a));
}
